/* main menu */
.site__header {
  top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.menu__button {
  color: var(--text-color);
}
.menu__button:focus,
.menu__button:hover {
  color: var(--text-color);
  box-shadow: unset;
}
.menu__button svg {
  width: 2em;
  height: 2em;
  fill: var(--text-color-2);
  color: var(--text-color-2);
}
.nav_ac {
  padding: 5px 15px;
  margin: 0;
  border: unset;
  background: var(--primary-color);
  font-size: 1.25rem;
  /* font-family: 'Merriweather', Marcellus, serif; */
  color: var(--text-color-2);
  line-height: 2;
  height: 50px;
  font-weight: bold;
  z-index: 1000;
}
.nav_ac:hover {
  color: var(--text-color-2);
}

.br-top,
.br-bottom,
.br-right,
.br-left {
  position: fixed;
  z-index: 999999;
  background: var(--primary-color);
}

.br-top {
  top: 0;
  height: 10px;
  left: 0;
  width: 100%;
}

.br-bottom {
  bottom: 0;
  left: 0;
  height: 10px;
  width: 100%;
}

.br-right {
  width: 10px;
  right: 0;
  top: 0;
  height: 100%;
}

.br-left {
  width: 10px;
  left: 0;
  top: 0;
  height: 100%;
}

/* roll down menu */
.site__navigation {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  visibility: hidden;
}

.menu__opened {
  visibility: visible !important;
}

.bg__menu {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  will-change: transform;
  transform: translateY(-100%);
  transition: .5s ease all;
}

.menu__opened .bg__menu {
  transform: translateY(0);
}

.menu__container .menu_item>a {
  color: var(--text-color-2);
  line-height: 1;
  font-size: 2rem;
  display: inline-block;
  position: relative;
  transition: color 250ms cubic-bezier(0, 0, 0.58, 1) 0s;
  padding: 4px 0px;
  text-decoration: none;
  font-family: 'Merriweather', Marcellus, serif;
}

.menu__container .menu_item>a:hover {
  color: var(--text-color-3);
}

.menu_footer {
  bottom: 0;
  font-size: 14px;
  background: var(--primary-color);
}

.menu_footer a {
  color: var(--text-color-2);
  font-size: 24px;
  text-decoration: none;
}

@media (min-width: 1024px) {
  .menu__container .menu_item>a {
    font-size: 3vw;
  }
}