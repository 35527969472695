.avatar{
  max-width: 180px;
}
.reveal{ opacity: .5; transition: .35s all;}
.reveal:hover{ opacity: 1; color: var(--text-color-3); }

.content .inner {
  margin: 0 auto;
}

@media all and (min-width: 1024px){
  .avatar{
    max-width: 250px;
  }
}