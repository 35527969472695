:root {
  --bg-color: #00111C;
  --primary-color: #00111C;
  --secondary-color: #fff;
  --text-color: #fff;
  --text-color-2: #fff;
  --text-color-3: #65b7ef;
  --overlay-color: rgb(12 12 12 / 63%);
  --white: #fff;
}

[data-theme="light"] {
  --bg-color: #ffffff;
  --primary-color: #ffffff;
  --secondary-color: #00111C;
  --text-color: #00111C;
  --text-color-2: #00111C;
  --text-color-3: #65b7ef;
  --overlay-color: rgb(255 255 255 / 70%);
  --white: #fff;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Figtree', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
  border-left: 10px solid var(--primary-color);
  border-right: 10px solid var(--primary-color);
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Merriweather', Marcellus, serif;
  font-weight: 400;
}

a,
a:hover {
  color: var(--text-color);
}

p {
  word-break: break-word;
  hyphens: auto;
}

.ovhidden {
  overflow: hidden;
}

.text_2,
.text_2:hover {
  color: var(--text-color-2);
}

.cursor__dot div {
  z-index: 999999 !important;
}

.cursor__dot div:last-child {
  background-color: var(--text-color-3) !important;
}

.cursor__dot div:first-child {
  filter: invert(1);
  background-color: var(--overlay-color) !important;
}

.color_pr {
  color: var(--primary-color) !important;
}

.color_sec {
  color: var(--secondary-color);
}

.btn {
  border-radius: 50px;
  padding: 4px 19px;
  color: var(--secondary-color);
  position: relative;
  border: var(--secondary-color) 2px solid;
  overflow: hidden;
  transition: all .35s;
  cursor: pointer;
}

.btn span {
  position: relative;
  z-index: 2;
}

.btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--text-color-3);
  transition: all .35s;
}

.btn:hover {
  color: var(--white);
  border: var(--text-color-3) 2px solid;
}

.btn:hover:after {
  width: 100%;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.fadeIn{
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.fadeOut{
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}